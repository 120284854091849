import Image from "next/image";
import Link from "next/link";
import React from "react";
import CustomButton from "./Buttons/CustomButton";

import errorImg from "@/Helper/images/errorPage/error500.svg";

import "@/app/error.css";
const Error505 = () => {
  return (
    <div className="error_container">
      <Image src={errorImg} width={585} height={321} alt="error" />
      <h1 className="error_heading">Opps! page not found</h1>
      <p className="error_subheading">
        Sorry, the page you’re looking for doesn’t exit. if you think something
        is broken, report a problem.
      </p>
      <div className="error_btn_area">
        <Link href={"/"}>
          <CustomButton Text={"Back to home"} PrimaryColorBtn />
        </Link>
        <Link href={"/contact-us"}>
          <CustomButton Text={"Report a problem"} />
        </Link>
      </div>
    </div>
  );
};

export default Error505;
