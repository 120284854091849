'use client'

import Error505 from "@/component/Error505";

export default function GlobalError({ error, reset }) {
  return (
    <html>
      <body>
     <div>
      <Error505 />
     </div>
      </body>
    </html>
  );
}
